<template>
  <v-container>
    <v-container>
      <div class="text-center">
        <h1 class="text-uppercase font-family-GoodTimes">Paiement</h1>
        <p class="mb-8">
          Retrouvez tous vos documents et toutes les informations
          nécessaires sur votre site en 1 clic
        </p>
      </div>

      <v-sheet elevation="3" class="pa-16">
        <h3 class="mb-8">Montant à payer</h3>
        <div>
          <v-text-field label="Montant"
                        :value=amount
                        v-model.number="amount"
                        prefix="€"
                        typeof="number">
          </v-text-field>
          <v-btn color="success"
                 :loading="loading"
                 @click="submit">
            Payer
          </v-btn>
        </div>
      </v-sheet>
    </v-container>
  </v-container>
</template>

<script>
import { getCustomerSelf, ingenicoPayment } from '@/services/user.service';

export default {
  name: 'Paiement',

  data: () => ({
    rules: {
      required: (value) => !!value || 'Champs requis.',
      integer: (value) => typeof value === 'number' || 'Doit être une valeur numérique.',
    },
    loading: false,
    amount: 10,
    clientId: null,
  }),
  methods: {
    submit() {
      // submit form
      this.loading = true;
      const amount = this.amount * 100;
      const vm = this;
      /* this.clientId, */
      ingenicoPayment({ amount }).then((response) => {
        console.log(response);
        vm.loading = false;
        window.location.href = `https://payment.${response.data.body.partialRedirectUrl}`;
      });
    },
  },
  computed: {
    //
  },
  async mounted() {
    try {
      const client = await getCustomerSelf();
      this.clientId = client.data.id;
    } catch (e) {
      //
    }
  },
};
</script>
