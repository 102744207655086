<template>
  <v-container>
    <v-container class="text-center">
      <h1 class="text-uppercase font-family-GoodTimes">Mes documents</h1>
      <p class="mb-8">
        Retrouvez tous vos documents et toutes les informations
        nécessaires sur votre site en 1 clic
      </p>

      <v-card min-height="20rem">
        <v-card-text>
          <document-list v-model="documents">
            <template #actions="{ file }">
              <v-row>
                <v-btn
                  rounded
                  class="mr-3 white--text font-weight-regular"
                  color="#63c9e3"
                  :href="getFilePathHref(file)"
                  target="_blank"
                  depressed
                >
                  <v-icon left>mdi-eye-outline</v-icon>
                  Consulter
                </v-btn>
                <v-btn
                  rounded
                  color="error"
                  class="font-weight-regular"
                  :href="getFilePathHref(file)"
                  target="_blank"
                  :download="file.name"
                  depressed
                >
                  <v-icon left>mdi-download</v-icon>
                  Télécharger
                </v-btn>
              </v-row>
            </template>
          </document-list>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { getCustomerSelf } from '@/services/user.service';
import DocumentList from '../components/DocumentList.vue';

export default {
  name: 'Documents',
  components: { DocumentList },

  data: () => ({
    documents: null,
  }),
  async mounted() {
    try {
      let client = await getCustomerSelf();
      client = client.data;
      this.documents = client.documents;
    } catch (e) {
      //
    }
  },
  methods: {
    getFilePathHref(file) {
      return process.env.VUE_APP_API_URL + file.file.path.substr(1);
    },
  },
};
</script>
