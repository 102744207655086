<template>
  <v-container class="text-center mt-16">
    <h1 class="text-h1 text-uppercase">Erreur 404</h1>
    <h2 class="text-h4">Cette page n'existe pas !</h2>
  </v-container>
</template>

<script>
export default {
  name: '404',
};
</script>

<style scoped>

</style>
