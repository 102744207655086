<template>
  <v-container>
    <v-container>
      <div class="text-center">
        <h1 class="text-uppercase font-family-GoodTimes">Maintenance</h1>
        <p class="mb-8">
          Retrouvez tous vos documents et toutes les informations
          nécessaires sur votre site en 1 clic
        </p>
      </div>

      <v-sheet elevation="2" class="pa-16" min-height="250">
        <h3 class="mb-8">Maintenance de votre site</h3>
        <p>
          La maintenance de votre site est prise en charge par Marge Communication
          jusqu'au: <b>{{ formatDate(maintenance.until) }}</b>
        </p>
      </v-sheet>
    </v-container>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { getCustomerSelf } from '@/services/user.service';

export default {
  name: 'Maintenance',

  data: () => ({
    maintenance: {
      until: null,
    },
  }),
  methods: {
    formatDate(date) {
      if (!date) return '';
      return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
    },
  },
  async mounted() {
    try {
      let client = await getCustomerSelf();
      client = client.data;
      this.maintenance = client.maintenance;
    } catch (e) {
      //
    }
  },
};
</script>
