<template>
  <v-container>
    <v-container>
      <div class="text-center">
        <h1 class="text-uppercase font-family-GoodTimes">Hébergement</h1>
        <p class="mb-8">
          Retrouvez tous vos documents et toutes les informations
          nécessaires sur votre site en 1 clic
        </p>
      </div>

      <v-sheet elevation="3" class="pa-16">
        <h3 class="mb-8">Votre hébergement</h3>
        <div>
          <p>Votre hébergeur est: <b>{{ hosting.host }}</b></p>
          <p v-if="hosting.from && hosting.until" class="mt-2">
            Il est valable du: <b>{{ formatDate(hosting.from) }}</b>
            <span class="ml-15">au: <b>{{ formatDate(hosting.until) }}</b></span>
          </p>
        </div>
        <h3 class="mb-8 mt-8">{{ domainsTitle }}</h3>
        <div v-for="(domain, d) in domains" :key="d">
          <p>Votre nom de domaine est: <b>{{ domain.name }}</b></p>
          <p class="mt-2">Il est valable du: <b>{{ formatDate(domain.from) }}</b>
          <span class="ml-15">au: <b>{{ formatDate(domain.until) }}</b></span></p>
        </div>
      </v-sheet>
    </v-container>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { getCustomerSelf } from '@/services/user.service';

export default {
  name: 'Hébergement',

  data: () => ({
    hosting: {
      host: '',
      until: null,
      from: null,
    },
    domains: [],
  }),
  methods: {
    formatDate(date) {
      if (!date) return '';
      return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
    },
  },
  computed: {
    domainsTitle() {
      return `Vo${this.domains.length < 2 ? 'tre' : 's'}\
       nom${this.domains.length < 2 ? '' : 's'} de domaine`;
    },
  },
  async mounted() {
    try {
      let client = await getCustomerSelf();
      client = client.data;
      this.domains = client.domains;
      this.hosting = client.hosting;
    } catch (e) {
      //
    }
  },
};
</script>
