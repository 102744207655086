<template>
  <v-container>
    <v-container class="text-center">
      <h2 class="font-weight-black mb-2">Bienvenue sur votre espace personnel</h2>
      <p>
        Retrouvez tous vos documents et toutes les informations
        nécessaires sur votre site en 1 clic
      </p>
    </v-container>

    <v-divider
      role="presentation"
      class="ma-auto my-6 mb-15 border-large"
      width="200px"
    />

    <v-container>
      <v-row justify="center">
        <CardButton
          titleText="Mes documents"
          :to="{ name: 'documents' }"
          filename="logo2.svg"
        />
        <CardButton
          titleText="Maintenance"
          :to="{ name: 'maintenance' }"
          filename="logo1.svg"
        />
        <CardButton
          titleText="Hébergement"
          :to="{ name: 'hosting' }"
          filename="logo3.svg"
        />
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import CardButton from '@/components/CardButton.vue';

export default {
  name: 'Home',

  components: {
    CardButton,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
  .border-large{
    border-width: 3px;
  }
</style>
