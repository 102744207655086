<template>
  <router-link
    :to="to"
    class="text-decoration-none ma-4"
    style="min-width:20%; width:20rem"
  >
    <v-card
      elevation="3"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="{ hovered: hover }"
      @click="((p) => $emit('click', p))"
    >
      <v-card-title
        class="text-center text-uppercase justify-center card-title"
      >
        {{ titleText }}
      </v-card-title>
      <v-card-content class="card-content">
        <v-img
               :lazy-src=src
               :src="require(`@/assets/${filename}`)"
               height="200"
               contain
               class="card-image"
        />
      </v-card-content>
    </v-card>
  </router-link>
</template>

<script>

export default {
  name: 'CardButton',

  data: () => ({
    hover: false,
  }),
  props: {
    titleText: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      required: true,
    },
    filename: {
      type: String,
    },
  },
};
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
</style>
<style scoped lang="sass">
  .hovered
    //background: var(--v-primary-base)
    background: #63c9e3
    color: #fff
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  .card-title
    font-family: 'Fredoka One', cursive
    font-weight: 100
    color: #000
    background: #fff
    display: flex
    align-items: end
    padding: 4rem 2rem 0 2rem

  .hovered .card-title
    color: #fff
    background: inherit

  .card-content
    display: flex
    justify-content: center
    align-items: center
    min-height: 12vw
    background: #fff
    color: #000
    padding: 2rem

  .card-image
      filter: brightness(0)

  .hovered .card-content
    color: #fff
    background: inherit

  .hovered .card-image
    filter: brightness(1)
</style>
